import React from 'react';
import ReactPlayer from 'react-player'

import './css/App.css';
import "materialize-css";

import Video from "./components/Video";

function App() {
  return (
    <div className="App" style={{height: "100%", width: "100%"}}>
      <Video></Video>
    </div>
  );
}

export default App;
