import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import "../css/Video.css"
import M from 'materialize-css';

import Searchbar from "./Searchbar"
import Buttons from './Buttons';
import SockJS from "sockjs-client"
import SockJsClient from 'react-stomp';
import Cookies from 'js-cookie'
import animals from "animals"

//const sizeFactor = 0.96;
const sizeFactor = 0.70;

export default class Video extends Component {

  constructor(props) {
    super(props);

    let name = "Random " + animals();
    if (Cookies.get("name")) {
      name = Cookies.get("name");
    }

    this.state = {
      url: "https://www.youtube.com/watch?v=IwLSrNu1ppI",
      playing: false,
      height: 0,
      width: 0,
      seconds: 0,
      socket: null,
      calledReady: false,
      startWhenReady: false,
      name: name,
      viewers: []
    } 
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.setState({socket: this.getWebSocket()});
  }

  resize = () => {

    if (window.innerHeight * 1.75 > window.innerWidth) {
      let height = window.innerHeight;
      let width = window.innerWidth * sizeFactor;
      height = window.innerWidth * 9/16 * sizeFactor;
      this.setState({height,width});
    } else {
      let height = window.innerHeight * sizeFactor;
      let width = window.innerWidth;
      width = window.innerHeight * 16/9 * sizeFactor;
      this.setState({height,width});
    }
    //console.log(width, height);
    //this.setState({height,width});
  }


  handleUrlChange = (url) => {
    this.setState({url, playing: false});
    this.state.socket.send(
      JSON.stringify({type: "VIDEO_CHANGE", payload: url})
    );
  }

  handleNameChange = name => {
    if (name !== this.state.name) {
      this.sendNameChange(name);
    }
  }

  sendNameChange = name => {
    this.setState({name});
    this.sendMessage(
      {type: "NAME_UPDATE", payload: name}
    );
    Cookies.set("name", name);
  }

  handlePlayPause = () => {
    const playing = !this.state.playing;
    if (playing) {
      this.sendMessage({type: "VIDEO_START"});
    } else {
      this.sendMessage({type: "VIDEO_PAUSE", payload: this.player.getCurrentTime()});
    }
    //this.setState({playing});
  }

  sendMessage = message => {
    this.state.socket.send(JSON.stringify(message));
    console.log("Sending: ", message);
  }

  getWebSocket = () => {
    let socket;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //socket = new WebSocket("ws://192.168.178.29:9292/socket");
      //socket = new WebSocket("ws://localhost:9292/socket");
      socket = new WebSocket("wss://s16.hardtke.io:8080/socket");
    } else {
      socket = new WebSocket("wss://s16.hardtke.io:8080/socket");
    }

    socket.onopen = e => {
      console.log("Connected, ", e);
      this.sendNameChange(this.state.name);
    };

    socket.onmessage = e => {
      //console.log(e);
      const json = JSON.parse(e.data);
      console.log(json);

      switch (json.type) {
        case "USER_UPDATE":
          this.setState({viewers: json.payload});
          break;
        case "VIDEO_UPDATE":
          let {seconds, playing, url} = json.payload;
          let time = Date.now();
          if (!this.state.calledReady && playing) {
            playing = false;
            this.setState({startWhenReady: true});
          }
          this.setState({playing, url, seconds}, () => {
            const delay = (Date.now() - time) / 1000;
            this.seekTo(seconds + delay);
            console.log("Delay: ", delay);
          });
          break;
      }

    };

    socket.onclose = e => {
      console.log("Socket Disconnected !", e);
      this.setState({socket: this.getWebSocket()});
    }
    return socket;
  }

  handleProgress = data => {
    console.log("Progress, ", data);
    const seconds = this.state.seconds;
    if (data.playedSeconds === 0 && data.loadedSeconds === 0) return;
    if (Math.abs(data.playedSeconds - seconds) > 1.2) {
      this.sendMessage({type:"VIDEO_TIME_JUMP", payload: data.playedSeconds});
      this.setState({timeJumped: true});
    } else {
      this.setState({seconds: data.playedSeconds, timeJumped: false});
    }
  }

  handleDuration = data => {
    console.log("Duration, ", data);
  }

  handlePause = () => {
    const seconds = this.state.seconds;
    console.log("Pause...", this.state.playing, !this.state.timeJumped);

    if (this.state.playing) {
      if (!this.state.timeJumped) {
        this.sendMessage({type: "VIDEO_PAUSE", payload: this.player.getCurrentTime()});
      } else {

       /*  if (Math.abs(data.playedSeconds - seconds) > 1.2) {
          this.sendMessage({type:"VIDEO_TIME_JUMP", payload: data.playedSeconds});
          this.setState({timeJumped: true});
        } else {
          this.setState({seconds: data.playedSeconds, timeJumped: false});
        } */

        this.setState({timeJumped: false});
      }
    }
  }

  handlePlay = () => {
    if (!this.state.playing) {
      this.sendMessage({type: "VIDEO_START"});
    }
  }

  handleSeek = d => {
    console.log("Seek: ", d);
  }

  ref = player => {
    this.player = player
  }

  handleReady = (p) => {
    console.log("Player Ready");
    if (this.state.calledReady) return;
    if (this.state.startWhenReady) {
      this.setState({calledReady: true, playing: true});
    } else {
      this.setState({calledReady: true});
    }
    this.sendMessage({type: "VIDEO_UPDATE_REQUEST"});
  }

  handleTimeSkip = (amount, forward) => {
    this.sendMessage({type: "VIDEO_TIME_JUMP", payload: this.player.getCurrentTime() + (forward ? 1 : -1) * amount});
  }

  handleBuffer = () => {
    console.log("Buffer started");
  }

  handleBufferEnded = () => {
    console.log("Buffer ended");
  }

  handleError = e => {
    //alert("Video error: " + e);
    console.error(e);
  }

  seekTo = seconds => {
    console.log(seconds);
    console.log(this.player.getCurrentTime());
    console.log("Skipping ? ", Math.abs(this.player.getCurrentTime() - seconds) > 100);
    if (Math.abs(this.player.getCurrentTime() - seconds) > 0.1) {
      this.player.seekTo(seconds);
      M.toast({html: 'Jumped to ' + seconds})
    }
  }

  render() {
    return (
      <div>

{/*         <SockJsClient url='http://localhost:9292/ws' topics={['/queue/notify']}
          onMessage={(msg) => { console.log(msg); }}
          ref={ (client) => { this.clientRef = client }} /> */}

        <Searchbar onSubmit={this.handleUrlChange}></Searchbar>
        <ReactPlayer className="react-player"
          ref={this.ref}
          onReady={this.handleReady}
          //height={"100%"}
          //width={"100%"} // can be used to fill avaliable space !
          width={this.state.width}
          height={this.state.height}
          url={this.state.url}
          playing={this.state.playing}
          controls={true}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          onPause={this.handlePause}
          onPlay={this.handlePlay}
          onSeek={this.handleSeek}
          onBuffer={this.handleBuffer}
          onBufferEnd={this.handleBufferEnded}
          onError={this.handleError}
        />
        <Buttons 
          isPlaying={this.state.playing} 
          onPlayPause={this.handlePlayPause} 
          viewers={this.state.viewers}
          onNameChange={this.handleNameChange}
          initialName={this.state.name}
          onTimeSkip={this.handleTimeSkip}
        />
      </div>
    )
  }
}
