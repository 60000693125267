import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import animals from "animals"


export default class Buttons extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.initialName,
      playClass: undefined,
      skipTime: 5,
      timeout: undefined
	  }
  }

  handleTextInput = e => {
    this.setState({name: e.target.value});
  }

  handleNameChange = e => {
    e.preventDefault();
    let name = this.state.name;
    if (name === "" || name === "loading...") {
      name = "Random " + animals();
    }
    this.setState({name});
    this.props.onNameChange(name);
  }

  handlePlayPause = () => {
    this.props.onPlayPause();
    if (!this.state.playClass) {
      this.setState({playClass: undefined});
      this.setState({playClass: "pulse"});
      const timeout = setTimeout(() => {
        this.setState({playClass: undefined, timeout});
      }, 500);
    } else {
      const timeout = this.state.timeout;
      this.setState({playClass: undefined, timeout: undefined});
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }

  render() {
	let i = 0;
    return (
      <div className="card white-text">
            <div className="card-content">
				<a id="replay" className="btn-floating z-depth-0 hoverable transparent waves-effect waves-dark" onClick={() => this.props.onTimeSkip(this.state.skipTime, false)}>
					<div id="replay-content">
						<i className="material-icons medium blue-grey-text" style={{fontSize: '40px'}}>replay</i>
						<span>5</span>
					</div>
				</a>
				<a className={"waves-effect waves-light btn-large btn-floating " 
					+ (this.props.isPlaying ? "deep-orange" : "green")
					+ (this.state.playClass ? " " + this.state.playClass : "")
					} onClick={this.handlePlayPause}>
             		<i className="material-icons left">{this.props.isPlaying ? "pause" : "play_arrow"}</i>
				</a>
				<a id="forward" className="btn-floating z-depth-0 hoverable transparent waves-effect waves-dark" onClick={() => this.props.onTimeSkip(this.state.skipTime, true)}>
					<div id="forward-content">
						<i className="material-icons medium blue-grey-text" style={{fontSize: '40px'}}>replay</i>
						<span>5</span>
					</div>
				</a>
           	</div>
        {
          this.props.viewers.map(v => {
            return (
              <div className="chip" key={i++}>
                {v === "" ? "loading..." : v}
              </div>
            )
          })
        }
          
        <form onSubmit={this.handleNameChange}>
            <div className="container">
              <div className="row valign-wrapper">
                  <div className="input-field col s12 m9">
                    <input 
                      id="name"
                      type="text"
                      value={this.state.name}
                      onChange={this.handleTextInput}  
                    />
                    <label htmlFor="name">Input text</label>
                </div>
                  <div className="col s12 m3">
                    <a onClick={this.handleNameChange} className="waves-effect waves-light btn" style={{textTransform: 'unset'}}>ChangeName<i className="material-icons right">repeat</i></a>
                  </div>
                </div>
            </div> 
        </form>
            
      </div>
    )
  }
}
