import React, { Component } from 'react'

export default class Searchbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      input: ""
    }

  }

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e.target.name.value);
    this.props.onSubmit(e.target.name.value);
  }

  render() {
    return (
      <div style={{width: "98%", paddingLeft: "2%"}}>
            <form className="col s12" onSubmit={this.handleSubmit}>
                <div className="row" style={{marginBottom: 0}}>
                    <div className="input-field col s11">
                        <input id="name" type="text" /* onChange={this.props.onChange} *//>
                        <label htmlFor="name">Url</label>
                    </div>
                    <div className="input-field col s1">
                        <button className="btn waves-effect waves-light" type="submit" name="action">Play
                        </button>
                    </div>
                </div>
            </form>
{/*           <label for="first_name">First Name</label>
          <a className="waves-effect waves-light btn-large"><i className="material-icons right"></i>button</a>
          <input placeholder="Video Url" type="text" /> */}
      </div>
    )
  }
}
